* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 18px;
  --scrollbarBG: #c0c0c0;
  --thumbBG: #000080;
}

.App::-webkit-scrollbar {
  width: 16px;
  background-color: var(--scrollbarBG);
}

.App::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border: 4px solid var(--scrollbarBG);
  border-radius: 8px;
}

.App::-webkit-scrollbar-thumb:hover {
  background-color: #0000ff;
}

@media (max-width: 768px) {
  .App {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .App {
    font-size: 14px;
  }
}
