.resume-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border: 1px solid #d3d3d3;
  position: relative;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  object-fit: cover;
}

.header-text {
  margin-left: 140px;
  text-align: center;
  padding-left: 75px;
  padding-right: 210px;
  margin-bottom: 0.5rem;
}

.resume-name {
  font-size: 26pt;
  margin: 0;
}

.resume-title {
  font-size: 18pt;
  color: #666;
  margin: 0;
}

.resume-contact-info {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.95em;
}

.resume-contact-info a {
  color: #007bff;
  text-decoration: none;
}

.resume-contact-info a:hover {
  text-decoration: underline;
}

h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.professional-experience-title {
  font-size: 19pt;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul {
  padding-left: 0;
  text-align: left;
}

ul li {
  margin-bottom: 0.5rem;
}

.proficiencies {
  text-decoration: underline;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

table td, table th {
  border: 1px solid #d3d3d3;
  padding: 8px;
  text-align: left;
  background-color: #fff;
  color: #000;
}

@media (max-width: 768px) {
  .resume-container {
      padding: 1rem;
  }

  .profile-pic {
      position: static;
      display: block;
      margin: 0 auto 1rem auto;
  }

  .header-text {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
  }

  .resume-name, .resume-title {
      text-align: center;
  }

  .resume-contact-info {
      text-align: center;
  }
}
