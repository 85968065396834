.about-container {
  padding: 0 5%;
  max-width: 800px;
  margin: 0 auto;
}

.about-header {
  text-align: center;
  margin-bottom: 2rem;
}

.about-content {
  margin-bottom: 1.5em;
  text-align: justify;
}

.about-socials {
  margin-top: 1.5em;
  text-decoration: underline;
  text-align: center;
}

.about-socials ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  text-align: center;
}

.about-socials li {
  display: inline-block;
  margin: 0.5em 1em;
}

@media (max-width: 768px) {
  .about-container {
    padding: 0 10px;
  }
}
