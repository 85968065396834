.projects-container {
  padding: 1rem;
  max-width: 1200px;
  margin: auto;
}

.projects-header {
  margin-bottom: 2rem;
}

.responsive-iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.linkedin-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid black;
}

@media (max-width: 1024px) {
  .projects-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .projects-container {
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  .projects-container {
    padding: 0;
  }

  .responsive-iframe-container {
    padding-bottom: 160%;
  }
}
